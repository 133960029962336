import React from 'react';
import { I18n } from 'react-redux-i18n';
import {
  Modal, Col, Row,
} from 'antd';
import AdvancedButton from '../../shared/AdvancedButton/AdvancedButton';
import AdvancedInput from '../../shared/AdvancedInput/AdvancedInput';


class CategoryDetailsModal extends React.PureComponent {
  render() {
    const {
      visible,
      loading,
      isEditing,
      categoryName,
      categoryParentName,
      handleCancel,
      handleSubmit,
      handleFieldChange,
    } = this.props;
    return (
      <Modal
        title={isEditing ? 'Edição de categoria' : 'Criação de categoria'}
        visible={visible}
        onCancel={(ev) => handleCancel(ev)}
        footer={[
          <AdvancedButton
            type="link"
            onClick={(ev) => handleCancel(ev)}
            key="cancel"
            text={I18n.t('routes.panel.users.userImportCsvModal.cancelText')}
          />,
          <AdvancedButton
            type="primary"
            loading={loading && loading > 0}
            onClick={(ev) => handleSubmit(ev)}
            key="upload"
            text="Confirmar"
          />,
        ]}
      >

        {categoryParentName && (
        <Row gutter={16}>
          <Col
            span={12}
            className="company-select"
          >
            <AdvancedInput
              label="Categoria pai"
              value={categoryParentName}
              onChange={(val) => handleFieldChange('categoryName', val)}
              disabled
            />
          </Col>
        </Row>
        )}
        <Row gutter={16}>
          <Col span={12}>
            <AdvancedInput
              label="Nome da categoria"
              value={categoryName}
              onChange={(val) => handleFieldChange('categoryName', val)}
            />
          </Col>
        </Row>
        <Row className="user-import-modal__input">
          <Col />
        </Row>
      </Modal>
    );
  }
}

export default CategoryDetailsModal;
