import getInstance from './config';

export default class UserApi {
  static async getUsersPaginated(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/user', { params });
    return data;
  }

  static async getUsersCsv(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/user/export', { params });
    return data;
  }

  static async getUserDetails(id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/user/${id}`);
    return data;
  }

  static async importCsv(companyId, csvData) {
    const instance = await getInstance();
    const { data } = await instance.post(`/user/import?companyId=${companyId}`, csvData);
    return data;
  }

  static async update(id, userData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/user/${id}`, userData);
    return data;
  }

  static async create(userData) {
    const instance = await getInstance();
    const { data } = await instance.post('/user', userData);
    return data;
  }

  static async remove(id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/user/${id}`);
    return data;
  }

  static async getUserSelectives(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/user/selectives', { params });
    return data;
  }
}
