import getInstance from './config';

export default class BannerApi {
  static async getBanners() {
    const instance = await getInstance();
    const { data } = await instance.get('/banner');
    return data;
  }

  static async uploadBanner(file, onUploadProgress) {
    const instance = await getInstance('multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s');
    return instance.post('/banner/upload', file, {
      onUploadProgress,
    });
  }

  static async saveBanners(banners) {
    const instance = await getInstance();
    const { data } = await instance.post('/banner', banners);
    return data;
  }
}
