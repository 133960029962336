import React from 'react';
import {
  Divider, Layout, Popconfirm, Button, Row, Col, message, Breadcrumb, Icon, Collapse,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  DeleteOutlined, EditOutlined, ProfileOutlined, SearchOutlined,
} from '@ant-design/icons';
import { Link } from '@reach/router';
import DataTable from '../../../components/shared/DataTable/DataTable';
import { AuthActions, ProductActions } from '../../../app/redux/actions';
import { AuthSelectors, LoadingSelectors, ProductSelectors } from '../../../app/redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import * as CurrencyUtils from '../../../app/utils/currency';
import QS from '../../../app/utils/query-string';

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = { params: {} };
    this.QS = new QS();
    this.dataTableRef = React.createRef();
  }

  getHrefWithParams(href) {
    const { params } = this.QS.get();
    return `${href}${params ? `?params=${JSON.stringify(params)}` : ''}`;
  }

  getStartPage() {
    const { params } = this.QS.get();
    const attribute = params && (params.page || params.offset);
    return attribute || null;
  }

  getProducts(params) {
    const { me } = this.props;
    const { getProductsPaginated } = this.props;
    this.QS.set({
      params: {
        ...params,
        ...(params.offset && { page: params.offset < 1 ? 1 : params.offset + 1 }),
      },
    });
    getProductsPaginated(params, me);
  }

  removeProduct(id, params) {
    this.props.removeProduct(id, () => {
      message.success(I18n.t('routes.panel.products.deleteSucces'));
      this.getProducts(params);
    });
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value ? value.toString() : null;
    this.setState({ params });
  }

  // eslint-disable-next-line class-methods-use-this
  cleanAdvancedFilters() {
    window.location.reload();
  }

  applyAdvancedFilters(params) {
    params = {
      ...params,
      page: 1,
      offset: 0,
    };
    this.getProducts(params);
  }

  render() {
    const { Content } = Layout;
    const { Panel } = Collapse;

    const { params } = this.state;
    const { usersPaginated, loading } = this.props;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.products.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse className="advanced-filter">
            <Panel
              header={<strong>{I18n.t('shared.advancedFilters.title')}</strong>}
              key="1"
            >
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label="Nome do produto"
                    value={params && params.name}
                    onChange={(val) => this.fieldChange('name', val)}
                    placeholder={I18n.t('shared.type')}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilters.clearButtonText')}
                    onClick={() => this.cleanAdvancedFilters()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilters.filterButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.applyAdvancedFilters(this.state.params)}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="products">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <ProfileOutlined />
                  </span>
                  {I18n.t('routes.panel.products.pageTitle')}
                </h2>
              </Col>
              <Col span={8} />
              <Col
                className="text-right"
                span={4}
              >
                <AdvancedButton
                  text={I18n.t('routes.panel.products.addNewButtonText')}
                  href={this.getHrefWithParams(`${I18n.t('routes.panel.productDetails.url')}add`)}
                  icon={<ProfileOutlined />}
                />
              </Col>
            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getProducts(parameters)}
                data={usersPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                startPage={this.getStartPage()}
                columns={[
                  {
                    key: I18n.t('routes.panel.products.dataTable.columns.name.key'),
                    title: I18n.t('routes.panel.products.dataTable.columns.name.title'),
                    render: (value) => value || '--',
                  },
                  {
                    key: I18n.t('routes.panel.products.dataTable.columns.description.key'),
                    title: I18n.t('routes.panel.products.dataTable.columns.description.title'),
                    render: (value) => value || '--',
                  },
                  {
                    key: I18n.t('routes.panel.products.dataTable.columns.price.key'),
                    title: I18n.t('routes.panel.products.dataTable.columns.price.title'),
                    render: (value) => CurrencyUtils.toCurrencyLocale(value) || '--',
                  },
                  {
                    key: I18n.t('routes.panel.products.dataTable.columns.size.key'),
                    title: I18n.t('routes.panel.products.dataTable.columns.size.title'),
                    render: (value) => value || '--',
                  },
                  {
                    key: I18n.t('routes.panel.products.dataTable.columns.actions.key'),
                    title: '',
                    render: (id) => (
                      <div className="dataTable__item--right">
                        <Popconfirm
                          placement="left"
                          title={I18n.t('shared.confirmTitle')}
                          onConfirm={() => this.removeProduct(id, params)}
                          okText={I18n.t('shared.yes')}
                          cancelText={I18n.t('shared.no')}
                        >
                          <Button type="link">
                            {I18n.t('routes.panel.products.dataTable.columns.actions.removeText')}
                            <DeleteOutlined />
                          </Button>
                        </Popconfirm>
                        <Link
                          to={this.getHrefWithParams(`${I18n.t('routes.panel.productDetails.url')}${id}`)}
                        >
                          {I18n.t('routes.panel.products.dataTable.columns.actions.goToDetailsText')}
                          &nbsp;&nbsp;
                          <EditOutlined />
                        </Link>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  usersPaginated: ProductSelectors.getProductsPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getProductsPaginated: (parameters, me) => dispatch(ProductActions.getProductsPaginated(parameters, me)),
  removeProduct: (id, callback) => dispatch(ProductActions.removeProduct(id, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);
