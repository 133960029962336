/* eslint-disable no-restricted-syntax */
import ReactQueryParams from 'react-query-params';

class QueryStringUtils extends ReactQueryParams {
  get(key) {
    let final = {
      ...this.queryParams,
    };

    if (key) {
      for (const i in final) {
        if (i === key) {
          final = decodeURI(final[i]);
        }
      }
    }

    return final;
  }

  getByKey(key) {
    let final = null;

    if (key) {
      for (const i in this.queryParams) {
        if (i === key) {
          final = decodeURI(this.queryParams[i]);
        }
      }
    }

    return final;
  }

  set(params, callback) {
    this.setQueryParams(params);

    if (typeof callback === 'function') {
      callback();
    }
  }

  remove(key) {
    let final = {
      ...this.get(),
    };

    if (key) {
      final[key] = undefined;
    } else {
      final = [];
    }

    this.set(final);
  }
}

export default QueryStringUtils;
