import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  message,
  Divider,
  Input,
  Select,
  Upload, Modal,
  Steps,
} from 'antd';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined, PlusOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';

import { ProductActions } from '../../redux/actions';
import {
  AuthSelectors, LoadingSelectors, ProductSelectors, CategorySelectors,
} from '../../redux/reducers';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import { getBase64 } from '../../utils/file';

const { TextArea } = Input;
const { Step } = Steps;

const steps = [
  {
    title: 'Informações principais do produto',
  },
  {
    title: 'Informações extras',
  },
  {
    title: 'Fotos',
  },
];

class ProductForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: null,
        description: null,
        price: null,
        size: null,
        colors: [],
        categories: [],
      },
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [],
      current: 0,
    };
  }


  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    const {
      productDetails,
      id,
    } = this.props;

    if (productDetails && id !== 'add') {
      this.setState({
        form: {
          name: productDetails.name,
          description: productDetails.description,
          price: productDetails.price,
          size: productDetails.size,
          colors: productDetails.colors
          && productDetails.colors.filter((i) => i.colorName !== null).map((i) => i.colorName),
          categories: productDetails.categories && productDetails.categories.map((i) => i.id),
        },
        fileList: productDetails.photos && productDetails.photos.map((o, i) => ({
          uid: `-${i}`,
          name: 'download.png',
          url: o.url,
          status: 'done',
        })),
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { form, fileList } = this.state;

    const productSchema = yup.object().shape({
      name: yup.string().required(),
      price: yup.string().required(),
      size: yup.string().required(),
      description: yup.string().required(),
    });

    productSchema
      .isValid(form)
      .then((valid) => {
        if (!valid) {
          return message.error(I18n.t('routes.panel.administratorDetails.messages.errors.generic'));
        }

        const productData = {
          name: form.name,
          description: form.description,
          price: form.price,
          size: form.size,
          categories: form.categories && form.categories.map((o) => ({ id: o })),
          colors: form.colors && form.colors.map((o) => ({ name: o })),
          photos: fileList && fileList.map((o, i) => ({ order: i, url: o.url || (o.response && o.response.url) })),
        };
        const { data, submitFunction } = this.props;
        submitFunction(data ? data.id : null, productData);
      }).catch((err) => { message.error(err); });
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handleChange = ({ fileList }) => this.setState({ fileList });

  onChangeStep = (current) => {
    this.setState({ current });
  }

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };


  next() {
    this.setState((prevState) => ({ current: prevState.current + 1 }));
  }

  prev() {
    this.setState((prevState) => ({ current: prevState.current - 1 }));
  }


  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  async customRequest({
    file, onProgress, onSuccess, onError,
  }) {
    const { uploadProductPhoto } = this.props;
    const data = new FormData();
    data.append('file', file);
    try {
      const response = await uploadProductPhoto(data, ({ total, loaded }) => {
        onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file);
      });
      onSuccess(response.data, file);
    } catch (error) {
      onError(error);
    }
  }


  render() {
    const {
      loading, categoriesPaginated,
    } = this.props;
    const {
      form, previewVisible, previewImage, fileList, previewTitle, current,
    } = this.state;

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Enviar</div>
      </div>
    );
    return (
      <Row>
        {loading === 0 && (
          <Col>
            <form
              name="productForm"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row
                gutter={16}
                className="mb-3  d-flex justify-content-center"
              >
                <Col span={24}>
                  <Steps
                    current={current}
                    onChange={this.onChangeStep}
                  >
                    {steps.map((item) => (
                      <Step
                        key={item.title}
                        title={item.title}
                      />
                    ))}
                  </Steps>
                </Col>
              </Row>

              {current <= 0 && (
                <>
                  <Row
                    gutter={16}
                    className="mb-2"
                  >
                    <Col span={8}>
                      Insira as principais informações do produto
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={8}>
                      <AdvancedInput
                        label={I18n.t('forms.product.name.label')}
                        placeholder={I18n.t('forms.product.name.placeholder')}
                        value={form && form.name}
                        onChange={(val) => this.fieldChange('name', val)}
                      />
                    </Col>
                    <Col span={8}>
                      <div style={{ paddingBottom: 5 }}>
                        {I18n.t('forms.product.price.label')}
                      </div>
                      <NumberFormat
                        placeholder={I18n.t('forms.product.price.placeholder')}
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix="R$"
                        value={form && Number(form.price)}
                        allowNegative={false}
                        decimalScale={2}
                        className="ant-input"
                        onValueChange={(val) => this.fieldChange('price', val.floatValue)}
                      />
                    </Col>
                    <Col span={8}>
                      <AdvancedInput
                        label={I18n.t('forms.product.size.label')}
                        placeholder={I18n.t('forms.product.size.placeholder')}
                        value={form && form.size}
                        onChange={(val) => this.fieldChange('size', val)}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <div style={{ paddingBottom: 5 }}>
                        {I18n.t('forms.product.description.label')}
                      </div>
                      <TextArea
                        placeholder={I18n.t('forms.product.description.placeholder')}
                        rows={5}
                        value={form && form.description}
                        onChange={(e) => this.fieldChange('description', e.target.value)}
                      />
                    </Col>
                  </Row>
                </>
              )}

              {current === 1 && (
                <>
                  <Row
                    gutter={16}
                    className="mb-2"
                  >
                    <Col span={8}>
                      Insira as informações extras do produto
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <div style={{ paddingBottom: 5 }}>
                        {I18n.t('forms.product.colors.label')}
                      </div>
                      <Select
                        placeholder={I18n.t('forms.product.colors.placeholder')}
                        mode="tags"
                        style={{ width: '100%' }}
                        onChange={(val) => this.fieldChange('colors', val)}
                        value={form && form.colors}
                      >
                        {form && form.colors && form.colors.map((item, index) => (
                          <Select.Option key={index.toString(36)}>
                            {item.colorName && item.colorName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                    <Col span={12}>
                      <AdvancedSelect
                        options={!!categoriesPaginated && categoriesPaginated}
                        label={I18n.t('forms.product.categories.label')}
                        placeholder={I18n.t('forms.product.categories.placeholder')}
                        onChange={(val) => this.fieldChange('categories', val)}
                        disabled={loading && loading > 0}
                        disableSearch
                        mode="multiple"
                        hasValue
                        value={form && form.categories}
                      />
                    </Col>
                  </Row>
                </>
              )}

              {current === 2 && (
                <>
                  <Row
                    gutter={16}
                    className="mb-2"
                  >
                    <Col span={8}>
                      Escolha até 4 fotos para o seu produto
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <div className="clearfix">
                        <Upload
                          listType="picture-card"
                          fileList={fileList}
                          onPreview={this.handlePreview}
                          onChange={this.handleChange}
                          customRequest={(options) => this.customRequest(options)}
                        >
                          {fileList.length >= 4 ? null : uploadButton}
                        </Upload>
                        <Modal
                          visible={previewVisible}
                          title={previewTitle}
                          footer={null}
                          onCancel={this.handleCancel}
                        >
                          <img
                            alt="example"
                            style={{ width: '100%' }}
                            src={previewImage}
                          />
                        </Modal>
                      </div>
                    </Col>
                  </Row>
                </>
              )}

              <Divider />

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  {current <= 0 ? (
                    <AdvancedButton
                      type="link"
                      text="Cancelar"
                      href={I18n.t('routes.panel.products.url')}
                    />
                  )
                    : (
                      <AdvancedButton
                        type="link"
                        text="Voltar"
                        onClick={() => this.prev()}
                      />
                    )}
                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />
                  {current === steps.length - 1 ? (
                    <>
                      <AdvancedButton
                        htmlType="submit"
                        text={I18n.t('forms.submitButtonText')}
                        icon={<SaveOutlined />}
                      />
                    </>
                  ) : (
                    <AdvancedButton
                      type="link"
                      text="Próximo"
                      onClick={() => this.next()}
                    />
                  )}
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  productDetails: ProductSelectors.getProductDetails(state),
  categoriesPaginated: CategorySelectors.getUsersPaginated(state),
});

const mapDispatchToProps = (dispatch) => ({
  uploadProductPhoto: (data, onUploadProgress) => dispatch(
    ProductActions.uploadProductPhoto(data, onUploadProgress),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  ProductForm,
);
