import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import BannerRequests from '../../api/banner';

export const ACTION_SAVE_BANNERS = 'ACTION_SAVE_BANNERS';

export const ACTION_CLEAN_BANNER = 'CLEAN_BANNER';

export const cleanBanner = () => ({
  type: ACTION_CLEAN_BANNER,
  banner: null,
});


export const getBanners = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    dispatch({
      type: ACTION_SAVE_BANNERS,
      payload: null,
    });
    const banners = await BannerRequests.getBanners();
    dispatch({
      type: ACTION_SAVE_BANNERS,
      payload: banners,
    });
  } catch (err) {
    message.error(I18n.t('routes.panel.categories.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const uploadBanner = (data, onUploadProgress) => async () => {
  try {
    return await BannerRequests.uploadBanner(data, onUploadProgress);
  } catch (err) {
    message.error(I18n.t('routes.panel.products.errors.generic'));
  }
};

export const saveBanners = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await BannerRequests.saveBanners(data);
    dispatch(getBanners());
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('routes.panel.products.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};
