/* eslint-disable class-methods-use-this */
import React from 'react';
import { Table } from 'antd';
import { I18n } from 'react-redux-i18n';

export const defaultValues = {
  pageSize: 20,
  total: 0,
  page: 1,
  data: null,
};

class DataTable extends React.Component {
  constructor(props) {
    super(props);
    const values = { ...defaultValues, page: (props.startPage && Number(props.startPage)) || 1 };
    this.state = values;
    if (!this.props.notGetOnStart) {
      this.props.getMethod(values);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      const { page } = this.state;

      this.setState({
        total: nextProps.data.count,
        page,
      });
    }

    this.setState({ data: nextProps.data });
  }

  getColumns(params) {
    return params.map((o) => ({
      title: o.title,
      dataIndex: o.key,
      key: o.key,
      render: o.render ? o.render : undefined,
      sorter: o.sorter ? o.sorter : undefined,
    }));
  }

  getRows(data) {
    data = data.map((o) => ({
      ...o,
      key: o.id ? o.id.toString() : Math.random().toString(36),
    }));
    return data;
  }

  handleTableChange(pagination, filters, sorter) {
    this.setState(
      {
        page: pagination.current,
        ...(pagination.pageSize && { pageSize: pagination.pageSize }),
      },
      () => {
        this.props.getMethod({
          offset: pagination.current > 1 ? pagination.current - 1 : 0,
          limit: pagination.pageSize || this.state.pageSize,
          ...(sorter && sorter.order && { field: sorter.field, order: sorter.order }),
        });
      },
    );
  }

  reset() {
    this.setState(defaultValues);
    this.props.getMethod(defaultValues);
  }

  rowSelection() {
    return {
      onChange: (selectedRowKeys, selectedRows) => {
        const { onSelectRow } = this.props;
        onSelectRow(selectedRows);
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        name: record.name,
      }),
    };
  }

  render() {
    const {
      columns, loading, selectable, paginated = true,
    } = this.props;
    const {
      total, page, pageSize, data,
    } = this.state;

    return (
      <div className="dataTable__wrapper">
        {selectable ? (
          <Table
            rowSelection={{
              type: 'checkbox',
              ...this.rowSelection(),
            }}
            dataSource={data && this.getRows(data.rows)}
            columns={this.getColumns(columns)}
            bordered={false}
            loading={loading}
            pagination={{
              current: page,
              total,
              page,
              pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30', '40', '50', '60', '70', '80', '90', '100'],
            }}
            size="middle"
            onChange={(ev) => this.handleTableChange(ev)}
            locale={{
              emptyText: I18n.t('shared.nothingToShow'),
            }}
          />
        ) : (
          <Table
            dataSource={data && this.getRows(data.rows)}
            columns={this.getColumns(columns)}
            bordered={false}
            loading={loading}
            pagination={
              paginated
                ? {
                  current: page,
                  total,
                  page,
                  pageSize,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '20', '30', '40', '50', '60', '70', '80', '90', '100'],
                }
                : {}
            }
            size="middle"
            onChange={(ev, filters, sorter, extra) => this.handleTableChange(ev, filters, sorter, extra)}
            locale={{
              emptyText: I18n.t('shared.nothingToShow'),
            }}
          />
        )}
      </div>
    );
  }
}

export default DataTable;
