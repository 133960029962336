import { Redirect, Router } from '@reach/router';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import Panel from './Panel';
import Public from './Public';
import Login from '../../containers/login';
import Administrators from '../../containers/panel/administrators';
import AdministratorDetails from '../../containers/panel/administratorDetails';
import Categories from '../../containers/panel/categories';
import Products from '../../containers/panel/products/Products';
import ProductDetails from '../../containers/panel/productDetails/ProductDetails';
import { ProfileType } from '../enum/profileType';
import Banners from '../../containers/panel/banners/Banners';

class Content extends React.PureComponent {
  render() {
    return (
      <Router>
        <Public
          path={I18n.t('routes.login.url')}
          container={<Login />}
          title={`${I18n.t('routes.login.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={I18n.t('routes.panel.administrators.url')}
          container={<Administrators />}
          title={`${I18n.t('routes.panel.administrators.pageTitle')} - ${I18n.t('application.name')}`}
          canAccess={[ProfileType.ADMIN]}
        />
        <Panel
          path={`${I18n.t('routes.panel.administratorDetails.url')}:id`}
          container={<AdministratorDetails />}
          title={`${I18n.t('routes.panel.administratorDetails.pageTitle')} - ${I18n.t('application.name')}`}
          canAccess={[ProfileType.ADMIN]}
        />
        <Panel
          path={I18n.t('routes.panel.categories.url')}
          container={<Categories />}
          title={`${I18n.t('routes.panel.categories.pageTitle')} - ${I18n.t('application.name')}`}
          canAccess={[ProfileType.ADMIN]}
        />
        <Panel
          path={I18n.t('routes.panel.products.url')}
          container={<Products />}
          title={`${I18n.t('routes.panel.products.pageTitle')} - ${I18n.t('application.name')}`}
          canAccess={[ProfileType.ADMIN]}
        />
        <Panel
          path={`${I18n.t('routes.panel.productDetails.url')}:id`}
          container={<ProductDetails />}
          title={`${I18n.t('routes.panel.productDetails.pageTitle')} - ${I18n.t('application.name')}`}
          canAccess={[ProfileType.ADMIN]}
        />
        <Panel
          path={I18n.t('routes.panel.banners.url')}
          container={<Banners />}
          title={`${I18n.t('routes.panel.banners.pageTitle')} - ${I18n.t('application.name')}`}
          canAccess={[ProfileType.ADMIN]}
        />
        <Redirect
          from={I18n.t('routes.panel.url')}
          to={I18n.t('routes.login.url')}
        />
      </Router>
    );
  }
}

export default Content;
