import React from 'react';
import { Translate } from 'react-redux-i18n';

export const ProfileType = {
  ADM: 1,
  USER: 2,
  OPERATOR: 3,
};

export const ProfileTypeArray = [
  { id: ProfileType.ADM, name: <Translate value="enum.profileType.admin" /> },
  { id: ProfileType.USER, name: <Translate value="enum.profileType.user" /> },
  { id: ProfileType.OPERATOR, name: <Translate value="enum.profileType.operator" /> },
];

export const GetProfileTypeTitle = (value) => ProfileTypeArray.find((o) => o.id === value).name;
