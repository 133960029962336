import React from 'react';
import {
  Breadcrumb, Col, Divider, Icon, Layout, message, Row, Spin,
} from 'antd';
import { connect } from 'react-redux';
import { ProfileOutlined } from '@ant-design/icons';
import { Link, navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import QS from '../../../app/utils/query-string';

import { ProductActions, CategoryActions } from '../../../app/redux/actions';
import { LoadingSelectors, ProductSelectors } from '../../../app/redux/reducers';
import { ViewType } from '../../../app/enum/viewType';
import ProductForm from '../../../app/forms/product/ProductForm';

class ProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: ViewType.EDIT,
    };
    this.QS = new QS();
  }


  componentDidMount() {
    const {
      getProductDetails, id, cleanProductDetails, getAllCategories,
    } = this.props;

    cleanProductDetails();

    this.setState({
      viewType: id !== 'add' ? ViewType.EDIT : ViewType.ADD,
    });
    getAllCategories();
    getProductDetails(id !== 'add' ? id : null);
  }

  getHrefWithParams(href) {
    const { params } = this.QS.get();
    return `${href}${params ? `?params=${JSON.stringify(params)}` : ''}`;
  }

  updateProduct(id, data) {
    const { updateProduct, createProduct, productDetails } = this.props;
    try {
      if (this.state.viewType === ViewType.ADD) {
        createProduct(data, () => {
          message.success(I18n.t('routes.panel.productDetails.messages.successCreate'));
          navigate(this.getHrefWithParams(I18n.t('routes.panel.products.url')));
        });
      } else {
        updateProduct(productDetails.id, data, () => {
          message.success(I18n.t('routes.panel.productDetails.messages.success'));
          navigate(this.getHrefWithParams(I18n.t('routes.panel.products.url')));
        });
      }
    } catch (e) {
      message.error(I18n.t('routes.panel.productDetails.messages.errors.generic'));
    }
  }

  render() {
    const { Content } = Layout;
    const { viewType } = this.state;
    const { productDetails, loading, id } = this.props;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={I18n.t('routes.panel.products.url')}>
                <Icon type="lock" /> <span>{I18n.t('routes.panel.products.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>
                {I18n.t(
                  viewType === ViewType.ADD
                    ? 'routes.panel.productDetails.pageTitleAdd'
                    : 'routes.panel.productDetails.pageTitle',
                )}
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div className="product-details">
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div className="panel__layout__content__title">
                <h2 className="panel__layout__content__title__value">
                  <span className="panel__layout__content__title__value__icon">
                    <ProfileOutlined />
                  </span>
                  {I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.productDetails.pageTitleAdd'
                      : 'routes.panel.productDetails.pageTitle',
                  )}
                </h2>
                <p className="panel__layout__content__title__value__description">
                  {I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.productDetails.pageDescriptionAdd'
                      : 'routes.panel.productDetails.pageDescription',
                  )}
                </p>
              </div>
              <Divider />
              <div>
                <Row>
                  <Col>
                    {(productDetails || viewType === ViewType.ADD) && (
                      <ProductForm
                        id={id}
                        submitFunction={(productId, data) => this.updateProduct(productId, data)}
                        viewType={viewType}
                        loading={loading}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Spin>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  productDetails: ProductSelectors.getProductDetails(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAllCategories: () => dispatch(CategoryActions.getAllCategories()),
  getProductDetails: (id) => dispatch(ProductActions.getProductDetails(id)),
  updateProduct: (id, data, callback) => dispatch(ProductActions.updateProduct(id, data, callback)),
  createProduct: (data, callback) => dispatch(ProductActions.createProduct(data, callback)),
  cleanProductDetails: () => dispatch(ProductActions.cleanProductDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
