import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import { addLoading, removeLoading } from './loading';
import ProductRequests from '../../api/product';

export const ACTION_SAVE_PRODUCTS_PAGINATED = 'ACTION_SAVE_PRODUCTS_PAGINATED';
export const ACTION_SAVE_PRODUCT_DETAILS = 'ACTION_SAVE_PRODUCT_DETAILS';

export const ACTION_CLEAN_PRODUCT = 'CLEAN_PRODUCT';

export const cleanProduct = () => ({
  type: ACTION_CLEAN_PRODUCT,
  product: null,
});

export const cleanProductDetails = () => ({
  type: ACTION_SAVE_PRODUCT_DETAILS,
  product: null,
});

export const getProductsPaginated = (parameters) => async (dispatch) => {
  dispatch(addLoading());
  try {
    parameters = {
      ...parameters,
      ...(parameters.page && { offset: parameters.page > 1 ? parameters.page - 1 : 0 }),
      ...(parameters.pageSize && { limit: parameters.pageSize }),
    };
    dispatch({
      type: ACTION_SAVE_PRODUCTS_PAGINATED,
      payload: null,
    });
    const productsPaginated = await ProductRequests.getProductsPaginated(parameters);
    dispatch({
      type: ACTION_SAVE_PRODUCTS_PAGINATED,
      payload: productsPaginated,
    });
  } catch (err) {
    message.error(I18n.t('routes.panel.products.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getProductDetails = (id) => async (dispatch) => {
  dispatch(addLoading());
  try {
    let data = null;
    dispatch({
      type: ACTION_SAVE_PRODUCT_DETAILS,
      payload: null,
    });
    if (id) {
      data = await ProductRequests.getProductDetails(id);
    }
    dispatch({
      type: ACTION_SAVE_PRODUCT_DETAILS,
      payload: data,
    });
  } catch (err) {
    message.error(I18n.t('routes.panel.products.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const updateProduct = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await ProductRequests.update(id, data);
    dispatch(getProductDetails(id));
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('routes.panel.products.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const createProduct = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const response = await ProductRequests.create(data);
    if (response && response.id) {
      dispatch(getProductDetails(response.id));
    }
    // dispatch(getProductsPaginated());
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('routes.panel.products.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const removeProduct = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await ProductRequests.remove(id);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('routes.panel.products.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const uploadProductPhoto = (data, onUploadProgress) => async () => {
  try {
    return await ProductRequests.uploadPhoto(data, onUploadProgress);
  } catch (err) {
    message.error(I18n.t('routes.panel.products.errors.generic'));
  }
};
