import Immutable from 'seamless-immutable';
import {
  ACTION_SAVE_BANNERS,
} from '../actions/banner';

const initialState = Immutable({
  banners: null,
});

export default function banner(
  state = initialState,
  action,
) {
  switch (action.type) {
    case ACTION_SAVE_BANNERS:
      state = {
        ...state,
        banners: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getBanners(state) {
  return state.banner.banners;
}
