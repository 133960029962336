/* eslint-disable camelcase */
import React from 'react';
import {
  Divider,
  Layout,
  Row,
  Col,
  message, Breadcrumb, Icon,
  Table,
  Popconfirm,
  Button,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  AppstoreAddOutlined,
  AppstoreOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';

import { CategoryActions } from '../../../app/redux/actions';
import { CategorySelectors } from '../../../app/redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import CategoryDetailsModal from '../../../components/panel/categoryDetailsModal/CategoryDetailsModal';

class Categories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      categoryName: '',
      categoryId: '',
      categoryParentId: '',
      categoryParentName: '',
      isCategoryModalVisible: false,
    };
    this.dataTableRef = React.createRef();
  }


  componentDidMount() {
    this.getCategories();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      categoriesPaginated,
    } = nextProps;
    if (categoriesPaginated) {
      this.setState({
        categories: categoriesPaginated,
      });
    }
  }

  onCategoryModalCancel() {
    this.setState({
      categoryName: '',
      categoryId: '',
      categoryParentId: '',
      categoryParentName: '',
      isCategoryModalVisible: false,
    });
  }

  async onFormSubmit() {
    const { createCategory, updateCategory } = this.props;
    const { categoryName, categoryParentId, categoryId } = this.state;
    const isEditing = !!categoryId;

    const callback = () => {
      this.onCategoryModalCancel();
      message.success(`Categoria ${isEditing ? 'editada' : 'criada'} com sucesso!`);
    };

    if (!categoryName) return message.error('O campo nome é obrigatório!');

    const categoryData = {
      name: categoryName,
    };

    if (categoryParentId) categoryData.parentId = categoryParentId;

    if (isEditing) updateCategory(categoryId, categoryData, callback);
    else createCategory(categoryData, callback);
  }

  onAddCategoryChildren(category) {
    this.setState({
      isCategoryModalVisible: true,
      categoryParentId: category.id,
      categoryParentName: category.name,
    });
  }

  onEditCategoryChildren(category) {
    this.setState({
      isCategoryModalVisible: true,
      categoryParentId: category.parentId,
      categoryParentName: null,
      categoryName: category.name,
      categoryId: category.id,
    });
  }

  onRemoveCategory(id) {
    const { removeCategory } = this.props;
    removeCategory(id, () => message.success('Categoria removida com sucesso!'));
  }

  getCategories(params) {
    const { getCategoriesPaginated } = this.props;
    getCategoriesPaginated(params);
  }

  fieldChange(name, value) {
    this.setState({ [name]: value });
  }

  render() {
    const { Content } = Layout;
    const {
      categories, isCategoryModalVisible,
      categoryName, categoryParentName,
    } = this.state;

    return (
      <>
        <CategoryDetailsModal
          visible={isCategoryModalVisible}
          isEditing
          categories={categories}
          categoryName={categoryName}
          categoryParentName={categoryParentName}
          handleCancel={() => this.onCategoryModalCancel()}
          handleFieldChange={(name, value) => this.fieldChange(name, value)}
          handleSubmit={(e) => this.onFormSubmit(e)}
        />

        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.categories.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div className="categories">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <AppstoreOutlined />
                  </span>
                  {I18n.t('routes.panel.categories.pageTitle')}
                </h2>
              </Col>
              <Col
                className="text-right"
                span={12}
              >

                <AdvancedButton
                  text={I18n.t('routes.panel.categories.addNewButtonText')}
                  icon={<AppstoreAddOutlined />}
                  onClick={() => this.setState({ isCategoryModalVisible: true })}
                />

              </Col>
            </Row>

            <Divider />

            <div>
              <>

                <Table
                  columns={[
                    {
                      title: 'Nome da categoria',
                      dataIndex: 'name',
                      key: 'name',
                    },
                    {
                      title: 'Ações',
                      key: 'id',
                      dataIndex: 'id',
                      render: (id, record) => (
                        <>
                          <Tooltip title="Adicionar subcategoria">
                            <Button
                              type="link"
                              onClick={() => this.onAddCategoryChildren(record)}
                              style={{ paddingLeft: 0 }}
                            >

                              <AppstoreAddOutlined />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Editar categoria">

                            <Button
                              type="link"
                              onClick={() => this.onEditCategoryChildren(record)}
                            >
                              <EditOutlined />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Excluir categoria">
                            <Popconfirm
                              placement="left"
                              title="Tem certeza?"
                              onConfirm={() => this.onRemoveCategory(record.id)}
                              okText="Sim"
                              cancelText="Não"
                            >
                              <Button
                                type="link"
                              >
                                <DeleteOutlined />
                              </Button>
                            </Popconfirm>
                          </Tooltip>
                        </>
                      ),
                    },
                  ]}
                  rowKey="id"
                  dataSource={categories}
                  expandRowByClick
                  locale={{
                    emptyText: I18n.t('shared.nothingToShow'),
                  }}
                />
              </>
            </div>

          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  categoriesPaginated: CategorySelectors.getUsersPaginated(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCategoriesPaginated: (parameters) => dispatch(CategoryActions.getCategories(parameters)),
  createCategory: (parameters, callback) => dispatch(CategoryActions.createCategory(parameters, callback)),
  updateCategory: (id, parameters, callback) => dispatch(CategoryActions.updateCategory(id, parameters, callback)),
  removeCategory: (id, callback) => dispatch(CategoryActions.removeCategory(id, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  Categories,
);
