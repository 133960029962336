import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import UserRequests from '../../api/user';
import { ProfileType } from '../../enum/profileType';

export const ACTION_SAVE_USERS_PAGINATED = 'ACTION_SAVE_USERS_PAGINATED';
export const ACTION_SAVE_USER_DETAILS = 'ACTION_SAVE_USER_DETAILS';
export const ACTION_SAVE_USER_SELECTIVES = 'ACTION_SAVE_USER_SELECTIVES';
export const ACTION_SAVE_USER_SELECTIVES_TWO = 'ACTION_SAVE_USER_SELECTIVES_TWO';

export const ACTION_CLEAN_USER = 'CLEAN_USER';

export const cleanUser = () => ({
  type: ACTION_CLEAN_USER,
  user: null,
});

export const cleanUserDetails = () => ({
  type: ACTION_SAVE_USER_DETAILS,
  user: null,
});

export const getUsersPaginated = (parameters, me) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    pageSize: parameters.pageSize || 10,
    total: parameters.total || 0,
    page: parameters.page || 1,
    orderBy: 'createdAt',
    desc: true,
  };

  if (parameters.profileType) {
    parameters = {
      ...parameters,
      profileType: parameters.profileType,
    };
  } else if (parameters.profileTypeList) {
    parameters = {
      ...parameters,
      profileTypeList: parameters.profileTypeList,
    };
  }

  if (me && me.profileType !== ProfileType.ADMIN) {
    parameters = {
      ...parameters,
      companyId: me.companyId,
    };
  }

  try {
    dispatch({
      type: ACTION_SAVE_USERS_PAGINATED,
      payload: null,
    });
    const usersPaginated = await UserRequests.getUsersPaginated(parameters);
    dispatch({
      type: ACTION_SAVE_USERS_PAGINATED,
      payload: usersPaginated,
    });
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const getUsersCsv = (parameters, me) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    page: parameters.page || 1,
    orderBy: 'createdAt',
    desc: true,
  };

  if (parameters.profileType) {
    parameters = {
      ...parameters,
      profileType: parameters.profileType,
    };
  } else if (parameters.profileTypeList) {
    parameters = {
      ...parameters,
      profileTypeList: parameters.profileTypeList,
    };
  }

  if (me && me.profileType !== ProfileType.ADMIN) {
    parameters = {
      ...parameters,
      companyId: me.companyId,
    };
  }

  try {
    return await UserRequests.getUsersCsv(parameters);
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const getUserDetails = (id) => async (dispatch) => {
  dispatch(addLoading());
  try {
    let data = null;
    dispatch({
      type: ACTION_SAVE_USER_DETAILS,
      payload: null,
    });
    if (id) {
      data = await UserRequests.getUserDetails(id);
    }
    dispatch({
      type: ACTION_SAVE_USER_DETAILS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const getUserSelectives = (me, searchInput) => async (dispatch) => {
  // dispatch(addLoading());
  try {
    let params = {
      searchInput,
    };

    if (me && me.profileType !== ProfileType.ADMIN) {
      params = {
        ...params,
        companyId: me.companyId,
      };
    }

    dispatch({
      type: ACTION_SAVE_USER_SELECTIVES,
      payload: null,
    });

    const userDetails = await UserRequests.getUserSelectives(params);

    dispatch({
      type: ACTION_SAVE_USER_SELECTIVES,
      payload: userDetails,
    });
  } catch (err) {
    console.log(err);
  } finally {
    // dispatch(removeLoading());
  }
};

export const getUserSelectivesTwo = (me, searchInput) => async (dispatch) => {
  // dispatch(addLoading());
  try {
    let params = {
      searchInput,
    };

    if (me && me.profileType !== ProfileType.ADMIN) {
      params = {
        ...params,
        companyId: me.companyId,
      };
    }
    dispatch({
      type: ACTION_SAVE_USER_SELECTIVES_TWO,
      payload: null,
    });
    const userDetails = await UserRequests.getUserSelectives(params);
    dispatch({
      type: ACTION_SAVE_USER_SELECTIVES_TWO,
      payload: userDetails,
    });
  } catch (err) {
    console.log(err);
  } finally {
    // dispatch(removeLoading());
  }
};

export const uploadImportCsv = (companyId, csvData, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await UserRequests.importCsv(companyId, csvData);
    callback();
  } catch (err) {
    console.log(err);
    message.error(I18n.t(`routes.panel.users.userImportCsvModal.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const updateUser = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await UserRequests.update(id, data);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const createUser = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    data = {
      ...data,
      profileType: ProfileType.USER,
    };
    await UserRequests.create(data);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.panel.userDetails.messages.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const createAdmin = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await UserRequests.create(data);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.panel.userDetails.messages.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const removeUser = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await UserRequests.remove(id);
    callback();
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};
