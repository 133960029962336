import React from 'react';
import {
  Divider,
  Layout,
  Popconfirm,
  Button,
  Row,
  Col,
  message, Breadcrumb, Icon,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { DeleteOutlined, EditOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from '@reach/router';
import DataTable from '../../../components/shared/DataTable';
import { AuthActions, UserActions } from '../../../app/redux/actions';
import { AuthSelectors, LoadingSelectors, UserSelectors } from '../../../app/redux/reducers';
import { GetProfileTypeTitle, ProfileType } from '../../../app/enum/profileType';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import * as DateUtils from '../../../app/utils/date';

class Administrators extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.dataTableRef = React.createRef();
  }

  getUsers(params) {
    const { me } = this.props;
    params = {
      ...params,
      // eslint-disable-next-line max-len
      profileType: ProfileType.ADM,
    };
    const { getUsersPaginated } = this.props;
    getUsersPaginated(params, me);
  }

  removeUser(id) {
    this.props.removeUser(id, () => {
      message.success(I18n.t('routes.panel.administrators.deleteSucces'));
      this.dataTableRef.current.reset();
    });
  }

  render() {
    const { Content } = Layout;
    const {
      usersPaginated,
      loading,
      me,
    } = this.props;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.administrators.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>
        <Content className="panel__layout__content">
          <div className="administrators">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <LockOutlined />
                  </span>
                  {I18n.t('routes.panel.administrators.pageTitle')}
                </h2>
              </Col>
              <Col span={8} />
              <Col
                className="text-right"
                span={4}
              >
                <AdvancedButton
                  text={I18n.t('routes.panel.administrators.addNewButtonText')}
                  href={`${I18n.t('routes.panel.administratorDetails.url')}add`}
                  icon={<LockOutlined />}
                />
              </Col>
            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getUsers(parameters)}
                data={usersPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.administrators.dataTable.columns.name.key'),
                      title: I18n.t('routes.panel.administrators.dataTable.columns.name.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.administrators.dataTable.columns.email.key'),
                      title: I18n.t('routes.panel.administrators.dataTable.columns.email.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.administrators.dataTable.columns.profileType.key'),
                      title: I18n.t('routes.panel.administrators.dataTable.columns.profileType.title'),
                      render: (value) => GetProfileTypeTitle(value) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.administrators.dataTable.columns.createdAt.key'),
                      title: I18n.t('routes.panel.administrators.dataTable.columns.createdAt.title'),
                      render: (createdAt) => DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm'),
                    },
                    {
                      key: I18n.t('routes.panel.administrators.dataTable.columns.actions.key'),
                      title: '',
                      render: (id) => (
                        <div className="dataTable__item--right">
                          <Popconfirm
                            placement="left"
                            title={I18n.t('shared.confirmTitle')}
                            onConfirm={() => this.removeUser(id)}
                            okText={I18n.t('shared.yes')}
                            cancelText={I18n.t('shared.no')}
                            disabled={me && me.id === id}
                          >
                            <Button
                              type="link"
                              disabled={me && me.id === id}
                            >
                              {I18n.t('routes.panel.administrators.dataTable.columns.actions.removeText')}
                              <DeleteOutlined />
                            </Button>
                          </Popconfirm>
                          <Link to={`${I18n.t('routes.panel.administratorDetails.url')}${id}`}>
                            {I18n.t('routes.panel.administrators.dataTable.columns.actions.goToDetailsText')}
                            &nbsp;&nbsp;<EditOutlined />
                          </Link>
                        </div>
                      ),
                    },
                  ]
                }
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  usersPaginated: UserSelectors.getUsersPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUsersPaginated: (parameters, me) => dispatch(UserActions.getUsersPaginated(parameters, me)),
  removeUser: (id, callback) => dispatch(UserActions.removeUser(id, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  Administrators,
);
