import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  message,
  Divider,
  Checkbox,
} from 'antd';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import { UserActions } from '../../redux/actions';
import { AuthSelectors, LoadingSelectors, UserSelectors } from '../../redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import { ProfileType, ProfileTypeArray } from '../../enum/profileType';
import { ViewType } from '../../enum/viewType';
import { validateEmail } from '../../utils/string';

class AdministratorForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changePasswordVisible: false,
      form: {
        name: null,
        email: null,
        password: null,
        passwordConfirmation: null,
        profileType: null,
        phone: null,
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const {
      userDetails,
      id,
    } = this.props;

    if (userDetails && id !== 'add') {
      this.setState({
        changePasswordVisible: false,
        form: {
          name: userDetails && userDetails.name,
          email: userDetails && userDetails.email,
          profileType: userDetails && userDetails.profileType,
          phone: userDetails && userDetails.phone,
          password: null,
          passwordConfirmation: null,
        },
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { form } = this.state;
    const { viewType, me } = this.props;

    if (!validateEmail(form.email)) {
      return message.error(I18n.t('routes.panel.administratorDetails.messages.errors.email'));
    }

    if (me && me.companyId) {
      form.companyId = me.companyId;
    }

    const userSchema = yup.object().shape({
      name: yup.string().required(),
      // companyId: yup.string().required(),
    });

    userSchema
      .isValid(form)
      .then((valid) => {
        if (!valid) {
          message.error(I18n.t('routes.panel.administratorDetails.messages.errors.generic'));
        } else {
          if (viewType === ViewType.ADD || this.state.changePasswordVisible) {
            if (!(form.password && form.passwordConfirmation)) {
              return message.error(I18n.t('routes.panel.administratorDetails.messages.errors.passwordsRequired'));
            }
            if (form.password !== form.passwordConfirmation) {
              return message.error(I18n.t('routes.panel.administratorDetails.messages.errors.passwordsMismatch'));
            }
          }

          const { data, submitFunction } = this.props;

          /* if (me && me.profileType === ProfileType.ADMIN) {
            form = {
              ...form,
              companyId: form.companyId !== 'all' ? form.companyId : null,
            };
          } */

          if (viewType === ViewType.EDIT && !this.state.changePasswordVisible) {
            delete form.password;
            delete form.passwordConfirmation;
          }

          submitFunction(data ? data.id : null, form);
        }
      }).catch((err) => { console.log(err); });
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value;
    if (name === 'profileType') {
      form.companyId = null;
    }
    this.setState({ form });
  }

  parseProfileTypeArray() {
    const { me } = this.props;
    let profiles = ProfileTypeArray;

    if (me.profileType !== ProfileType.ADMIN) {
      profiles = profiles.filter((o) => o.id !== ProfileType.ADMIN);
    }

    profiles = profiles.filter((o) => o.id !== ProfileType.USER);

    return profiles;
  }

  render() {
    const {
      loading, viewType,
    } = this.props;
    const { form, changePasswordVisible } = this.state;
    return (
      <Row>
        {loading === 0 && (
          <Col>
            <form
              name="administratorForm"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <AdvancedInput
                    label={I18n.t('forms.administrator.email.label')}
                    value={form && form.email}
                    onChange={(val) => this.fieldChange('email', val)}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={12}>
                  <AdvancedInput
                    label={I18n.t('forms.administrator.name.label')}
                    value={form && form.name}
                    onChange={(val) => this.fieldChange('name', val)}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col
                  span={12}
                  className="company-select"
                >
                  <AdvancedSelect
                    options={this.parseProfileTypeArray()}
                    label={I18n.t('forms.administrator.profileType.label')}
                    value={form && form.profileType}
                    onChange={(val) => this.fieldChange('profileType', val)}
                    disabled={loading && loading > 0}
                    disableSearch
                  />
                </Col>
                <Col span={12}>
                  <AdvancedInput
                    label={I18n.t('forms.administrator.phone.label')}
                    value={form && form.phone}
                    onChange={(val) => this.fieldChange('phone', val)}
                  />
                </Col>
              </Row>

              {viewType === ViewType.EDIT && (
                <Row
                  gutter={16}
                  className="form-password-divider"
                >
                  <Col span={6}>
                    <Checkbox
                      onChange={(e) => this.setState({ changePasswordVisible: e.target.checked })}
                    >
                      {I18n.t('routes.panel.administratorDetails.changePasswordPhrase')}
                    </Checkbox>
                  </Col>
                </Row>
              )}

              {(viewType === ViewType.ADD || changePasswordVisible) && (
                <Row gutter={16}>
                  <Col span={12}>
                    <AdvancedInput
                      label={I18n.t('forms.administrator.password.label')}
                      value={form && form.password}
                      onChange={(val) => this.fieldChange('password', val)}
                      isPassword
                    />
                  </Col>
                  <Col span={12}>
                    <AdvancedInput
                      label={I18n.t('forms.administrator.passwordConfirmation.label')}
                      value={form && form.passwordConfirmation}
                      onChange={(val) => this.fieldChange('passwordConfirmation', val)}
                      isPassword
                    />
                  </Col>
                </Row>
              )}

              <Divider />

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('forms.goBackButtonText')}
                    href={I18n.t('routes.panel.administrators.url')}
                  />
                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />
                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('forms.submitButtonText')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  userDetails: UserSelectors.getUserDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
  uploadImportCsv: (companyId, csvData, callback) => dispatch(
    UserActions.uploadImportCsv(companyId, csvData, callback),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  AdministratorForm,
);
