import React from 'react';
import {
  Breadcrumb, Col, Icon, Layout, Row, Upload, Modal, Divider, message,
} from 'antd';
import { connect } from 'react-redux';
import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { I18n } from 'react-redux-i18n';
import { AuthSelectors, LoadingSelectors, BannerSelectors } from '../../../app/redux/reducers';
import { BannerActions } from '../../../app/redux/actions';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import { getBase64 } from '../../../app/utils/file';

import QS from '../../../app/utils/query-string';

class Banners extends React.Component {
  constructor(props) {
    super(props);
    this.QS = new QS();
    this.dataTableRef = React.createRef();
    this.state = {
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [],
    };
  }

  async componentDidMount() {
    const { getBanners, cleanBanner } = this.props;

    cleanBanner();

    await getBanners();
    const { banners } = this.props;
    this.setState({
      fileList:
        banners
        && banners.map((o, i) => ({
          uid: `-${i}`,
          name: 'download.png',
          url: o.url,
          response: { url: o.url },
          status: 'done',
        })),
    });
  }

  async onClick() {
    const { saveBanners } = this.props;
    const { fileList } = this.state;
    try {
      await saveBanners(fileList.map((banner) => ({ url: banner.response.url })));
      message.success(I18n.t('routes.panel.banners.saveSuccessMessage'));
    } catch (err) {
      message.error(I18n.t('routes.panel.banners.saveErrorMessage'));
    }
  }

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handleChange = ({ fileList }) => this.setState({ fileList });

  async uploadBanners({
    file, onProgress, onSuccess, onError,
  }) {
    const { uploadBanners, banners } = this.props;
    const data = new FormData();
    data.append('file', file);
    try {
      const response = await uploadBanners(data, ({ total, loaded }) => {
        onProgress({ percent: parseFloat(Math.round((loaded / total) * 100).toFixed(2)) }, file);
      });
      banners.push(response.data);
      onSuccess(response.data, file);
    } catch (error) {
      onError(error);
    }
  }

  removeBanner(bannerToRemove) {
    const { fileList } = this.state;
    const updatedFileList = fileList.filter((banner) => banner.response.url !== bannerToRemove.response.url);
    this.setState({ fileList: updatedFileList });
  }

  render() {
    const { Content } = Layout;
    const {
      previewVisible, previewImage, fileList, previewTitle,
    } = this.state;

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Enviar</div>
      </div>
    );
    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.banners.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <Row
            gutter={16}
            className="mb-2"
          >
            <Col span={12}>Arraste suas imagens aqui para fazer upload e transformá-las em banners</Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <div className="clearfix">
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={this.handlePreview}
                  onChange={this.handleChange}
                  customRequest={(options) => this.uploadBanners(options)}
                  onRemove={(data) => this.removeBanner(data)}
                >
                  {uploadButton}
                </Upload>
                <Modal
                  visible={previewVisible}
                  title={previewTitle}
                  footer={null}
                  onCancel={this.handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: '100%' }}
                    src={previewImage}
                  />
                </Modal>
              </div>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col
              span={24}
              className="text-right"
            >
              <AdvancedButton
                htmlType="submit"
                text={I18n.t('forms.submitButtonText')}
                icon={<SaveOutlined />}
                onClick={() => this.onClick()}
              />
            </Col>
          </Row>

        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  banners: BannerSelectors.getBanners(state),
});

const mapDispatchToProps = (dispatch) => ({
  uploadBanners: (data, onUploadProgress) => dispatch(BannerActions.uploadBanner(data, onUploadProgress)),
  saveBanners: (data, callback) => dispatch(BannerActions.saveBanners(data, callback)),
  cleanBanner: () => dispatch(BannerActions.cleanBanner()),
  getBanners: () => dispatch(BannerActions.getBanners()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Banners);
