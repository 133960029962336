export default {
  application: {
    name: 'monjolinho - Dashboard',
    shortDescription: 'monjolinho',
    title: 'Monjolinho - Administrativo',
    version: {
      title: 'Versão',
      number: '1.0.0',
    },
    footer: {
      poweredBy: 'Powered by',
      poweredByBrand: 'monjolinho',
      poweredByBrandUrl: 'https://mblabs.com.br',
    },
  },
  shared: {
    notFoundSearch: 'Nada encontrado.',
    selectSomeValue: 'Selecione...',
    typeToContinue: 'Digite algo para pesquisar...',
    type: 'Digite...',
    yes: 'Sim',
    no: 'Não',
    loading: 'Carregando...',
    confirmTitle: 'Você tem certeza?',
    logout: {
      menuText: 'Sair do sistema',
      confirmation: 'Você tem certeza que deseja sair?',
      confirmButton: 'Tenho certeza, sair.',
    },
    nothingToShow: 'Nada para exibir por aqui.',
    hours: 'horas',
    minutes: 'minutos',
    seconds: 'segundos',
    distanceUnit: 'metros',
    distanceUnitSmall: 'm',
    cantAccess: 'Você não tem permissão para visualizar esta página.',
    advancedFilters: {
      title: 'Filtros avançados',
      rangeLabel: 'Período',
      userOneLabel: 'Usuário 1 (nome ou registro)',
      userTwoLabel: 'Usuário 2 (nome ou registro)',
      loginLabel: 'Registro 1',
      loginTwoLabel: 'Registro 2',
      filterButtonText: 'Filtrar',
      exportButtonText: 'Exportar',
      clearButtonText: 'Limpar filtros',
      rangeStart: 'Data inicial',
      rangeEnd: 'Data final',
    },
  },
  routes: {
    login: {
      url: '/',
      pageTitle: 'Entrar',
      content: {
        header: 'Entre com o e-mail e senha cadastrados',
        email: 'E-mail',
        emailPlaceholder: 'Seu e-mail cadastrado',
        password: 'Senha',
        passwordPlaceholder: 'Sua senha segura',
        company: 'Empresa',
        companyAll: 'Todas',
        continueButton: 'Entrar no sistema',
        welcome: 'Bem vindo(a) novamente.',
      },
      errors: {
        fields: 'Verifique os campos e tente novamente',
        invalid_credentials: 'Credenciais inválidas, verifique e tente novamente.',
        invalid_email_or_password: 'Credenciais inválidas, verifique e tente novamente.',
        'invalid-password': 'Credenciais inválidas, verifique e tente novamente.',
        'invalid-email': 'Credenciais inválidas, verifique e tente novamente.',
        user_not_found: 'Credenciais inválidas, verifique e tente novamente.',
        // eslint-disable-next-line max-len
        user_requires_company: 'Você precisa estar em uma empresa para continuar. Entre em contato com um administrador.',
        user_no_access: 'Você não tem acesso a essa tela. Entre em contato com um administrador.',
        user_invalid_token: 'Token inválido ou expirado. Entre novamente.',
      },
      messages: {
        welcome: 'Bem vindo(a) novamente.',
      },
    },

    panel: {
      pageTitle: 'Dashboard',
      url: '/painel',

      // modules
      administrators: {
        sidebarTitle: 'Administradores',
        url: '/painel/administradores',
        pageTitle: 'Administradores',
        deleteSucces: 'Administrador removido com sucesso.',
        addNewButtonText: 'Adicionar novo',
        dataTable: {
          columns: {
            name: {
              key: 'name',
              title: 'Nome',
            },
            email: {
              key: 'email',
              title: 'E-mail',
            },
            profileType: {
              key: 'profileType',
              title: 'Tipo de conta',
            },
            company: {
              key: 'company',
              title: 'Empresa',
            },
            createdAt: {
              key: 'createdAt',
              title: 'Data de cadastro',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              goToDetailsText: 'Detalhes',
              removeText: 'Remover',
            },
          },
        },
      },
      users: {
        sidebarTitle: 'Usuários',
        url: '/painel/usuarios',
        pageTitle: 'Usuários',
        addNewButtonText: 'Adicionar novo',
        userImportCsvButtonText: 'Importar CSV',
        userExportCsvButtonText: 'Exportar CSV',
        deleteSucces: 'Usuário removido com sucesso.',
        matches: 'matches',
        match: 'match',
        userImportCsvModal: {
          okText: 'Realizar upload',
          cancelText: 'Cancelar',
          title: 'Importar usuários por CSV',
          templateButtonOne: 'Clique aqui',
          templateButtonTwo: 'para realizar o download do template de importação.',
          selectFile: 'Clique aqui ou arraste arquivos',
          selectFileDescription: 'Tipo de arquivo permitido: .csv de até 2mb',
          errors: {
            invalidFileSize: 'Arquivo muito grande, divida em um menor e tente novamente.',
            maxSelectedFiles: 'Apenas um arquivo por vez.',
            generic: 'Algo deu errado ao tentar ler o arquivo, verifique e tente novamente.',
            invalidDateFormat: 'Existe uma data com formato não suportado.',
            invalidLoginFormat: 'Existe um login com formato não suportado.',
            genericUpload: 'Algo deu errado ao tentar subir o arquivo, verifique e tente novamente.',
            login_already_used: 'Já existe um usuário com o login informado, verifique e tente novamente.',
            invalid_id: 'Selecione a empresa.',
          },
          success: 'Usuário(s) importado(s) com sucesso.',
        },
        dataTable: {
          columns: {
            name: {
              key: 'name',
              title: 'Nome',
            },
            email: {
              key: 'email',
              title: 'E-mail',
            },
            login: {
              key: 'login',
              title: 'Registro',
            },
            lastSyncedAt: {
              key: 'lastSyncedAt',
              title: 'Último sync',
            },
            createdAt: {
              key: 'createdAt',
              title: 'Data de cadastro',
            },
            bornAt: {
              key: 'bornAt',
              title: 'Data de nascimento',
            },
            matchCount: {
              key: 'matchCount',
              title: 'Qtd matches',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              goToDetailsText: 'Detalhes',
              removeText: 'Remover',
            },
          },
        },
        errors: {
          generic: 'Algo deu errado, tente novamente mais tarde.',
        },
      },

      userDetails: {
        url: '/painel/usuarios/',
        pageTitle: 'Detalhes do usuário',
        pageTitleAdd: 'Adicionar usuário',
        pageDescription: 'Preencha os campos abaixo para editar um usuário.',
        pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um novo usuário',
        changePasswordPhrase: 'Desejo alterar a senha do usuário',
        messages: {
          success: 'Usuário atualizado com sucesso.',
          successCreate: 'Usuário adicionado com sucesso.',
          errors: {
            login_already_used: 'O registro especificado já está em uso.',
            email_already_used: 'O registro especificado já está em uso.',
            generic: 'Algo deu errado, verifique os campos tente novamente.',
            email: 'E-mail inválido, verifique e tente novamente.',
            passwordsMismatch: 'A senha e confirmação de senha não estão iguais.',
            passwordsRequired: 'A senha e confirmação de senha são requeridas.',
            'invalid-phone': 'Telefone inválido',
            'invalid-name': 'Nome inválido',
            'invalid-password': 'Senha inválido, deve conter somente números e letras',
          },
        },
      },

      administratorDetails: {
        url: '/painel/administradores/',
        pageTitle: 'Detalhes do administrador',
        pageTitleAdd: 'Adicionar administrador',
        pageDescription: 'Preencha os campos abaixo para editar um administrador.',
        pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um novo administrador',
        changePasswordPhrase: 'Desejo alterar a senha do administrador',
        messages: {
          success: 'Administrador atualizado com sucesso.',
          successCreate: 'Usuário adicionado com sucesso.',
          errors: {
            generic: 'Algo deu errado, verifique os campos tente novamente.',
            email: 'E-mail inválido, verifique e tente novamente.',
            passwordsMismatch: 'A senha e confirmação de senha não estão iguais.',
            passwordsRequired: 'A senha e confirmação de senha são requeridas.',
          },
        },
      },
      productDetails: {
        url: '/painel/produtos/',
        pageTitle: 'Detalhes do produto',
        pageTitleAdd: 'Adicionar produto',
        pageDescription: 'Preencha os campos abaixo para editar um produto.',
        pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um novo produto',
        changePasswordPhrase: 'Desejo alterar a senha do produto',
        messages: {
          success: 'Produto atualizado com sucesso.',
          successCreate: 'Produto adicionado com sucesso.',
          errors: {
            generic: 'Algo deu errado, verifique os campos tente novamente.',
            email: 'E-mail inválido, verifique e tente novamente.',
            passwordsMismatch: 'A senha e confirmação de senha não estão iguais.',
            passwordsRequired: 'A senha e confirmação de senha são requeridas.',
          },
        },
      },
      categories: {
        sidebarTitle: 'Categorias',
        url: '/painel/categorias',
        pageTitle: 'Categorias',
        addNewButtonText: 'Adicionar nova',
        deleteSucces: 'Categoria removido com sucesso.',
        matches: 'matches',
        match: 'match',
        userImportCsvModal: {
          okText: 'Realizar upload',
          cancelText: 'Cancelar',
          title: 'Importar Categorias por CSV',
          templateButtonOne: 'Clique aqui',
          templateButtonTwo: 'para realizar o download do template de importação.',
          selectFile: 'Clique aqui ou arraste arquivos',
          selectFileDescription: 'Tipo de arquivo permitido: .csv de até 2mb',
          errors: {
            invalidFileSize: 'Arquivo muito grande, divida em um menor e tente novamente.',
            maxSelectedFiles: 'Apenas um arquivo por vez.',
            generic: 'Algo deu errado ao tentar ler o arquivo, verifique e tente novamente.',
            invalidDateFormat: 'Existe uma data com formato não suportado.',
            invalidLoginFormat: 'Existe um login com formato não suportado.',
            genericUpload: 'Algo deu errado ao tentar subir o arquivo, verifique e tente novamente.',
            login_already_used: 'Já existe um usuário com o login informado, verifique e tente novamente.',
            invalid_id: 'Selecione a empresa.',
            category_already_used: 'Categoria já existente, por favor utilize outro nome',
          },
          success: 'Usuário(s) importado(s) com sucesso.',
        },
        dataTable: {
          columns: {
            name: {
              key: 'name',
              title: 'Nome',
            },
            email: {
              key: 'email',
              title: 'E-mail',
            },
            login: {
              key: 'login',
              title: 'Registro',
            },
            lastSyncedAt: {
              key: 'lastSyncedAt',
              title: 'Último sync',
            },
            createdAt: {
              key: 'createdAt',
              title: 'Data de cadastro',
            },
            bornAt: {
              key: 'bornAt',
              title: 'Data de nascimento',
            },
            matchCount: {
              key: 'matchCount',
              title: 'Qtd matches',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              goToDetailsText: 'Detalhes',
              removeText: 'Remover',
            },
          },
        },
        errors: {
          generic: 'Algo deu errado, tente novamente mais tarde.',
        },
      },
      products: {
        sidebarTitle: 'Produtos',
        url: '/painel/produtos',
        pageTitle: 'Produtos',
        addNewButtonText: 'Adicionar novo',
        deleteSucces: 'Produto removido com sucesso.',
        matches: 'matches',
        match: 'match',
        userImportCsvModal: {
          okText: 'Realizar upload',
          cancelText: 'Cancelar',
          title: 'Importar Produtos por CSV',
          templateButtonOne: 'Clique aqui',
          templateButtonTwo: 'para realizar o download do template de importação.',
          selectFile: 'Clique aqui ou arraste arquivos',
          selectFileDescription: 'Tipo de arquivo permitido: .csv de até 2mb',
          errors: {
            invalidFileSize: 'Arquivo muito grande, divida em um menor e tente novamente.',
            maxSelectedFiles: 'Apenas um arquivo por vez.',
            generic: 'Algo deu errado ao tentar ler o arquivo, verifique e tente novamente.',
            invalidDateFormat: 'Existe uma data com formato não suportado.',
            invalidLoginFormat: 'Existe um login com formato não suportado.',
            genericUpload: 'Algo deu errado ao tentar subir o arquivo, verifique e tente novamente.',
            login_already_used: 'Já existe um usuário com o login informado, verifique e tente novamente.',
            invalid_id: 'Selecione a empresa.',
          },
          success: 'Usuário(s) importado(s) com sucesso.',
        },
        dataTable: {
          columns: {
            name: {
              key: 'name',
              title: 'Nome',
            },
            description: {
              key: 'description',
              title: 'Descrição',
            },
            price: {
              key: 'price',
              title: 'Preço',
            },
            size: {
              key: 'size',
              title: 'Tamanho',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              goToDetailsText: 'Detalhes',
              removeText: 'Remover',
            },
          },
        },
        errors: {
          generic: 'Algo deu errado, tente novamente mais tarde.',
        },
      },
      banners: {
        url: '/painel/banners',
        pageTitle: 'Banners',
        sidebarTitle: 'Banners',
        saveSuccessMessage: 'Banners salvos com sucesso!',
        saveErrorMessage: 'Algo deu errado ao tentar salvar os banners, por favor contate o desenvolvedor.',
      },

      pushNotification: {
        url: '/painel/push-notification/',
        pageTitle: 'Criar notificações',
        sidebarTitle: 'Notificações',
        form: {
          blockTitle: 'Detalhes da notificação',
          types: {
            label: 'Desejo enviar para',
          },
          title: {
            label: 'Título da notificação',
          },
          description: {
            label: 'Descrição',
          },
          hasImage: {
            label: 'Incluir imagem no corpo da notificação?',
          },
          image: {
            dragger: {
              title: 'Imagem do corpo da notificação',
              tip: 'Clique aqui ou arraste e solte uma imagem dentro da área tracejada',
            },
          },
          datatable: {
            title: 'Usuários',
            name: {
              key: 'name',
              label: 'Nome',
            },
            email: {
              key: 'email',
              label: 'E-mail',
            },
            cellphone: {
              key: 'cellphone',
              label: 'Telefone',
            },
            createdAt: {
              key: 'createdAt',
              label: 'Data cadastro',
            },
          },
        },
        types: {
          one: {
            id: '1',
            name: 'Clientes',
          },
          two: {
            id: '2',
            name: 'Usuários X',
          },
          three: {
            id: '3',
            name: 'Usuários Y',
          },
        },
        preview: {
          titlePlaceHolder: 'Digite um título',
          descriptionPlaceHolder: 'Digite uma descrição',
        },
      },
    },

    mock: {
      url: '/',
      pageTitle: 'Collapsable',
      itemOne: 'Collapsable 1',
      itemTwo: 'Collapsable 2',
      itemThree: 'Collapsable 3',
    },
  },
  forms: {
    user: {
      name: {
        label: 'Nome completo',
      },
      nameOrMiddleName: {
        label: 'Nome e/ou sobrenome',
      },
      email: {
        label: 'E-mail',
      },
      login: {
        label: 'Registro',
      },
      cellphone: {
        label: 'Telefone',
      },
      bornAt: {
        label: 'Data de nascimento',
      },
      companyId: {
        label: 'Empresa',
      },
      assetName: {
        label: 'Localização',
      },
      departmentName: {
        label: 'departamento',
      },
      managerName: {
        label: 'Responsável',
      },
    },
    administrator: {
      name: {
        label: 'Nome completo',
      },
      email: {
        label: 'E-mail',
      },
      profileType: {
        label: 'Tipo de perfil',
      },
      companyId: {
        label: 'Empresa',
      },
      password: {
        label: 'Senha',
      },
      passwordConfirmation: {
        label: 'Confirmação da senha',
      },
      phone: {
        label: 'Telefone/Celular',
      },
    },
    product: {
      name: {
        label: 'Nome',
        placeholder: 'Digite...',
      },
      description: {
        label: 'Descrição',
        placeholder: 'Digite...',
      },
      price: {
        label: 'Preço',
        placeholder: 'Digite...',
      },
      size: {
        label: 'Tamanho',
        placeholder: 'Digite...',
      },
      categories: {
        label: 'Categorias',
        placeholder: 'Digite...',
      },
      colors: {
        label: 'Cores do produto',
        placeholder: 'Insira...',
      },
      photos: {
        label: 'Telefone/Celular',
        placeholder: 'Digite...',
      },
    },
    goBackButtonText: 'Cancelar',
    submitButtonText: 'Salvar',
  },
  enum: {
    profileType: {
      admin: 'Admin',
      user: 'Usuário',
      operator: 'Operador',
    },
  },
};
